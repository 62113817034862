import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from './sidebar';
import axios from 'axios';
import Navbar from './navbar';

const BoltsPage = ({ pipeSizes }) => {
  const [roller, setRoller] = useState('');
  const [quantity, setQuantity] = useState('');
  const [price, setPrice] = useState('');
  const [nut, setNut] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('http://89.116.121.215:8082/nutHandle');

        if (!response.data || !Array.isArray(response.data)) {
          console.error('Invalid API response:', response.data);
          return;
        }

        const selectedRollerSize = localStorage.getItem("selectedRollerSize");
        const filteredData = response.data.filter(item => item.roller === selectedRollerSize);
        const handleSet = new Set(filteredData.map(item => item.handle));
        const handleArray = Array.from(handleSet);

        setNut(handleArray);
      } catch (error) {
        console.error('Error fetching nut handle data:', error);
      }
    };

    fetchData();
  }, []);

  const skip = async () => {

    const editArray = JSON.parse(localStorage.getItem("editArray"));
    let billingTableData =
      JSON.parse(localStorage.getItem("billingTableData")) || [];

    if (editArray && editArray.random_id) {
      const editedItemIndex = billingTableData.findIndex((item) => item.random_id === editArray.random_id);
      let currentAmount = parseInt(billingTableData[editedItemIndex].price) || 0;

      if (editedItemIndex !== -1) {

        const previousBoltName = billingTableData[editedItemIndex].nutHandle;
        if (previousBoltName) {
          // Fetch the cap's price
          const response = await axios.get(`http://89.116.121.215:8082/nutHandle?handle=${previousBoltName}`);
          const handlePrice = response.data.price || 0;
          currentAmount = parseInt(billingTableData[editedItemIndex].price) - handlePrice;
        }


        billingTableData[editedItemIndex].nutHandle = "";


   
        localStorage.setItem("Price", currentAmount);

        localStorage.setItem("billingTableData", JSON.stringify(billingTableData));
        localStorage.setItem("InvoiceData", localStorage.getItem("billingTableData"));
        navigate('/billing');
      }

      else {
        localStorage.setItem("nutBolt", "");
        navigate('/billing');
      }
    }
    else {
      localStorage.setItem("nutBolt", "");
        navigate('/billing');
    }
  }

  const handleAddBolt = async (handle) => {
    try {
      const editArray = JSON.parse(localStorage.getItem('editArray'));
      let billingTableData = JSON.parse(localStorage.getItem('billingTableData')) || [];
  
      if (editArray && editArray.random_id) {
        const editedItemIndex = billingTableData.findIndex((item) => item.random_id === editArray.random_id);
  
        if (editedItemIndex !== -1) {
          const previousBoltName = billingTableData[editedItemIndex].nutHandle;
          billingTableData[editedItemIndex].nutHandle = handle;
          let currentAmount = parseInt(billingTableData[editedItemIndex].price) || 0;

          const response = await axios.get("http://89.116.121.215:8082/nutHandle");
          const handleData = response.data;
          console.log(handleData)
          console.log(currentAmount);
          const selectedBoltEntry = handleData.find(item => item.handle === handle);
          currentAmount = currentAmount  + selectedBoltEntry.price;
          billingTableData[editedItemIndex].price = currentAmount;


          localStorage.setItem("nutBolt", handle);

          localStorage.setItem("Price", currentAmount);
          localStorage.setItem("billingTableData", JSON.stringify(billingTableData));
          localStorage.setItem("InvoiceData", localStorage.getItem("billingTableData"));
          navigate("/billing");
          return;
        }
      }
  
      // Fetch the price of the selected handle from the API
      const response = await axios.get("http://89.116.121.215:8082/nutHandle");
      const handleData = response.data.find(item => item.handle === handle);
      let currentAmount = parseInt(localStorage.getItem("Price")) || 0;
  
      if (handleData) {
        // Log the price of the selected handle
        console.log("Price of", handle, ":", handleData.price);
  
        // Calculate the new amount by adding the price of the selected handle
        currentAmount += handleData.price;
  
        // Update the price in localStorage
        localStorage.setItem("Price", currentAmount);
        localStorage.setItem("nutBolt", handle); 
        // Add bolt with the selected handle
        await axios.post('http://89.116.121.215:8082/handle', {
          roller,
          handle,
          quantity,
          price: handleData.price // Use the price from the API response
        });
        
        // Reset fields after successful addition
        setRoller('');
        setQuantity('');
        setPrice('');
  
        // Navigate to billing page or any other appropriate page
        navigate('/billing');
      } else {
        console.log("Selected handle not found in nutHandle data.");
      }
    } catch (error) {
      console.error('Error adding bolt:', error);
    }
  };
  
  return (
    <div className="app-container">
      <Sidebar />
      <div className="main-content">
        <Navbar />
        <div className="product-container">
          {nut.map((item) => (
            <div className='card card-body size-card' key={item} onClick={() => handleAddBolt(item)}>
              <div style={{ marginTop: 45, fontSize: 20 }}><b>{item}</b></div>
            </div>
          ))}
        </div>
        <div className="skip-btn" style={{ textAlign: 'left' }}>
          <button type="button" style={{ backgroundColor: 'white', color: "navy", border: "none", paddingLeft: 30, paddingRight: 30, paddingTop: 10, paddingBottom: 10 }} onClick={() => skip()}><b>Skip</b></button>
        </div>
      </div>
    </div>
  );
};

export default BoltsPage;

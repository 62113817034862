import React, { useState, useEffect, useRef } from "react";
import Sidebar from "./sidebar";
import Navbar from "./navbar";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Login = () => {
  const hasEffectRun = useRef(false);
  const navigate = useNavigate();
  const [custId, setCustId] = useState('');
  const [formData, setFormData] = useState({
    loginValue: ""  }); // Corrected variable name

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  useEffect(() => {
    localStorage.clear();
  }, [])

  useEffect(() => {
    if (!hasEffectRun.current) {
      console.log("Effect runs only once");
      hasEffectRun.current = true;
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const apiUrl = "http://89.116.121.215:8082/login";
  
    if (!formData.loginValue) {
      console.error("Login value is required.");
      return;
    }
  
    try {
      const response = await axios.post(apiUrl, {
        loginValue: formData.loginValue,
      });
  
      console.log("API Response:", response.data);
  
      if (response.data && response.data.Login) {
        const { id, customer_name, phone_number, company_name, city } = response.data.result[0];
        setCustId(id);
        localStorage.setItem("CUST_ID", id);
        localStorage.setItem("Name", customer_name);
        localStorage.setItem("company", company_name);

        localStorage.setItem("phone_number", phone_number);
        localStorage.setItem("city", city)
        navigate("/cat-order");
      } else {
        // Handle other cases, e.g., show an error message to the user
        console.log("No such account found");
      }
    } catch (error) {
      console.error("API Error:", error);
      // Handle error response if needed
    }
  };
  

  return (
    <div>
      <div className="app-container">
        <Sidebar />
        <div className="main-content">
          <Navbar />
          <div className="card card-login">
            <h2>Customer Details</h2>
            <br></br>
            <form className="billing-form" onSubmit={handleSubmit}>
              <div className="d-flex">
                <div className="col-md-12" style={{ paddingRight: 15 }}>
                  <label htmlFor="phoneNumber" className="form-label">
                    Username
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="loginValue"
                    placeholder="Enter username"
                    value={formData.loginValue}
                    onChange={handleInputChange}
                    name="loginValue"
                  />
                </div>
              </div>

              <button
                type="submit"
                className="submit-btn btn btn-lg mt-3 mb-3"
                style={{ backgroundColor: "navy", color: "white" }}
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

import React, { useState, useEffect, useRef } from "react";
import "./RecordOfTransactions.css";
import Sidebar from "./sidebar";
import axios from "axios"; // Import axios library

const RecordOfTransactions = () => {
  const hasEffectRun = useRef(false);

  const [transactions, setTransactions] = useState([]);
  const [amount, setAmount] = useState("");
  const [tableData, setTableData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [id, setId] = useState(null);
  const [cashAmountInput, setCashAmountInput] = useState("");
  const [chequeAmountInput, setChequeAmountInput] = useState("");
  const [showCashPopup, setShowCashPopup] = useState(false);
  const [showChequePopup, setShowChequePopup] = useState(false);

  useEffect(() => {
    // Your existing useEffect logic...
  }, [setTableData]);

  const handleCashSubmit = async () => {
    try {
      // Make sure selectedTransaction exists
      if (!selectedTransaction) return;

      // Prepare the data to send to the API
      const requestData = {
        orderId: selectedTransaction.order_id,
        cash_amount: cashAmountInput, // Use the amount entered by the user
        orderDetails: selectedTransaction,
      };

      // Call the API to update the cash amount
      const response = await axios.put(
        "http://89.116.121.215:8082/updateOrderCashAmount",
        requestData
      );

      // Handle the response as needed, e.g., show a success message
      console.log("Cash amount updated successfully:", response.data);

      // Close the popup
      handleClosePopup("cash");

      handleSearch();
    } catch (error) {
      console.error("Error updating cash amount:", error);
    }
  };

  const handleChequeSubmit = async () => {
    try {
      // Make sure selectedTransaction exists
      if (!selectedTransaction) return;

      // Prepare the data to send to the API
      const requestData = {
        orderId: selectedTransaction.order_id,
        cheque_amount: chequeAmountInput, // Use the amount entered by the user
        orderDetails: selectedTransaction,
      };

      // Call the API to update the cheque amount
      const response = await axios.put(
        "http://89.116.121.215:8082/updateOrderChequeAmount",
        requestData
      );

      // Handle the response as needed, e.g., show a success message
      console.log("Cheque amount updated successfully:", response.data);

      // Close the popup
      handleClosePopup("cheque");
      handleSearch();
    } catch (error) {
      console.error("Error updating cheque amount:", error);
    }
  };

  const handleSearch = async () => {
    try {
      const response = await axios.post("http://89.116.121.215:8082/api/orders", {
        customer_name: localStorage.getItem("cust_name"),
      });

      setTransactions(response.data.orders);
      setTableData(response.data.orders);
      console.log(tableData);
      setTotalAmount(calculateTotalAmount(response.data.orders));
      setId(response.data.orders[0].cust_id);

      const accountsCustomerResponse = await axios.post(
        "http://89.116.121.215:8082/api/accounts_customer",
        {
          cust_id: response.data.orders[0].cust_id,
        }
      );

      console.log("Accounts Customer Response:", accountsCustomerResponse.data);
      setAmount(accountsCustomerResponse.data.total_balance);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
    localStorage.setItem("cust_name", e.target.value);
  };

  const calculateTotalAmount = (orders) => {
    return orders.reduce((total, order) => total + order.amount_to_pay, 0);
  };

  const handleRowClick = (transaction) => {
    setSelectedTransaction(transaction);
  };

  const handleClosePopup = (popupType) => {
    if (popupType === "cash") {
      setShowCashPopup(false);
    } else if (popupType === "cheque") {
      setShowChequePopup(false);
    }
    setSelectedTransaction(null);
  };

  const uniqueOrderIds = Array.from(
    new Set(tableData.map((transaction) => transaction.order_id))
  );

  return (
    <div>
      <div className="app-container">
        <Sidebar />
        <div className="main-content">
          <div className="record-container">
            <div className="heading-container d-flex">
              <h2>Record of Transactions</h2>
              <div className="search-container">
                <input
                  type="text"
                  placeholder="Search..."
                  className="search-input"
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                />
                <button onClick={handleSearch}>Search</button>
              </div>
            </div>

            {tableData.length > 0 ? (
              <>
                <table>
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Customer Name</th>
                      <th>Description</th>
                      <th>Transaction Amount</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {uniqueOrderIds.map((orderId, index) => {
                      const transactionsWithOrderId = tableData.filter(
                        (transaction) => transaction.order_id === orderId
                      );
                      return (
                        <React.Fragment key={index}>
                          {transactionsWithOrderId.map(
                            (transaction, innerIndex) => (
                              <tr key={innerIndex}>
                                {innerIndex === 0 && (
                                  <td rowSpan={transactionsWithOrderId.length}>
                                    {index + 1}
                                  </td>
                                )}
                                {innerIndex === 0 && (
                                  <td rowSpan={transactionsWithOrderId.length}>
                                    {localStorage.getItem("cust_name")}
                                  </td>
                                )}
                                <td>
                                  {transaction.roller_size &&
                                    transaction.roller_size !== "null" && (
                                      <>
                                        {transaction.roller_size}
                                        {((transaction.pipe_size &&
                                          transaction.pipe_size !== "null") ||
                                          (transaction.cat &&
                                            transaction.cat !== "null") ||
                                          (transaction.roller_name &&
                                            transaction.roller_name !==
                                              "null") ||
                                          (transaction.cap &&
                                            transaction.cap !== "null") ||
                                          (transaction.poly &&
                                            transaction.poly !== "null") ||
                                          (transaction.nutHandle &&
                                            transaction.nutHandle !==
                                              "null")) &&
                                          " --> "}
                                      </>
                                    )}
                                  {transaction.pipe_size &&
                                    transaction.pipe_size !== "null" && (
                                      <>
                                        {transaction.pipe_size}
                                        {((transaction.cat &&
                                          transaction.cat !== "null") ||
                                          (transaction.roller_name &&
                                            transaction.roller_name !==
                                              "null") ||
                                          (transaction.cap &&
                                            transaction.cap !== "null") ||
                                          (transaction.poly &&
                                            transaction.poly !== "null") ||
                                          (transaction.nutHandle &&
                                            transaction.nutHandle !==
                                              "null")) &&
                                          " --> "}
                                      </>
                                    )}
                                  {transaction.cat &&
                                    transaction.cat !== "null" && (
                                      <>
                                        {transaction.cat}
                                        {((transaction.roller_name &&
                                          transaction.roller_name !== "null") ||
                                          (transaction.cap &&
                                            transaction.cap !== "null") ||
                                          (transaction.poly &&
                                            transaction.poly !== "null") ||
                                          (transaction.nutHandle &&
                                            transaction.nutHandle !==
                                              "null")) &&
                                          " --> "}
                                      </>
                                    )}
                                  {transaction.roller_name &&
                                    transaction.roller_name !== "null" && (
                                      <>
                                        {transaction.roller_name}
                                        {((transaction.cap &&
                                          transaction.cap !== "null") ||
                                          (transaction.poly &&
                                            transaction.poly !== "null") ||
                                          (transaction.nutHandle &&
                                            transaction.nutHandle !==
                                              "null")) &&
                                          " --> "}
                                      </>
                                    )}
                                  {transaction.cap &&
                                    transaction.cap !== "null" && (
                                      <>
                                        {transaction.cap}
                                        {transaction.poly &&
                                          transaction.poly !== "null" &&
                                          " --> "}
                                      </>
                                    )}
                                  {transaction.poly &&
                                    transaction.poly !== "null" && (
                                      <>
                                        {transaction.poly}
                                        {transaction.nutHandle &&
                                          transaction.nutHandle !== "null" &&
                                          " --> "}
                                      </>
                                    )}
                                  {transaction.nutHandle &&
                                    transaction.nutHandle !== "null" && (
                                      <>{transaction.nutHandle}</>
                                    )}
                                  {transaction.spray_name &&
                                    transaction.spray_name !== "null" && (
                                      <>{transaction.spray_name}</>
                                    )}
                                  {transaction.tools &&
                                    transaction.tools !== "null" && (
                                      <>{transaction.tools}</>
                                    )}
                                </td>
                                <td>{transaction.amount_to_pay}</td>
                                {innerIndex === 0 && (
                                  <td rowSpan={transactionsWithOrderId.length}>
                                    <div>
                                      <button
                                        className={
                                          transaction.cash_paid >=
                                          transaction.cash_amount
                                            ? "red-text"
                                            : "btn-action"
                                        }
                                        onClick={() => {
                                          handleRowClick(transaction);
                                          if (
                                            transaction.cash_paid !==
                                            transaction.cash_amount
                                          ) {
                                            setShowCashPopup(true);
                                          }
                                        }}
                                        style={{
                                          marginRight: 5,
                                          marginBottom: 2,
                                        }}
                                      >
                                        Cash:{" "}
                                        {transaction.cash_amount -
                                          transaction.cash_paid}
                                      </button>

                                      <button
                                        className={
                                          transaction.cheque_paid >=
                                          transaction.cheque_amount
                                            ? "red-text"
                                            : "btn-action"
                                        }
                                        onClick={() => {
                                          handleRowClick(transaction);
                                          if (
                                            transaction.cheque_paid !==
                                            transaction.cheque_amount
                                          ) {
                                            setShowChequePopup(true);
                                          }
                                        }}
                                      >
                                        Cheque:{" "}
                                        {transaction.cheque_amount -
                                          transaction.cheque_paid}
                                      </button>
                                    </div>
                                  </td>
                                )}
                              </tr>
                            )
                          )}
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </table>
                {selectedTransaction && showCashPopup && (
                  <div className="popup open">
                    <div className="popup-content">
                      <div className="popup-header">
                        <h3>Transaction Details</h3>
                        <button className="close-btn" onClick={() => handleClosePopup("cash")}>
        &times;
      </button>

                          
                      </div>
                      <p>Amount: {selectedTransaction.cash_amount}</p>
                      <div style={{ marginBottom: 5 }}>
                        <label>Amount to pay:</label>
                        <input
                          type="number"
                          value={cashAmountInput}
                          onChange={(e) => setCashAmountInput(e.target.value)}
                        />
                      </div>
                      <button
                        style={{
                          marginTop: 20,
                          backgroundColor: "navy",
                          color: "white",
                        }}
                        onClick={handleCashSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}

                {selectedTransaction && showChequePopup && (
                  <div className="popup open">
                    <div className="popup-content">
                      <div className="popup-header">
                        <h3>Transaction Details</h3>
                        <button className="close-btn" onClick={() => handleClosePopup("cheque")}>
        &times;
      </button>
                      </div>
                      <p>Amount: {selectedTransaction.cheque_amount}</p>
                      <div style={{ marginBottom: 5 }}>
                        <label>Amount to pay:</label>
                        <input
                          type="number"
                          value={chequeAmountInput}
                          onChange={(e) => setChequeAmountInput(e.target.value)}
                        />
                      </div>
                      <button
                        style={{
                          marginTop: 20,
                          backgroundColor: "navy",
                          color: "white",
                        }}
                        onClick={handleChequeSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="no-transactions-message">
                No transactions currently.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecordOfTransactions;

import React, { useState, useEffect } from "react";
import { PDFViewer, Document, Page, View, Text, StyleSheet } from "@react-pdf/renderer";


const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#ffffff",
    padding: 10,
  },
  section: {
    flexGrow: 1,
  },
  heading: {
    fontSize: 18,
    marginBottom: 10,
    textAlign: "center",
  },
  customerInfo: {
    marginBottom: 20,
    fontSize: 10,  // Specify the desired font size
  },
  billingInfo: {
    marginTop: 20,
    fontSize: 10, // Specify the desired font size
  },
  billingData: {
    fontSize: 10, // Specify the desired font size
    marginTop: 1
  },
  firstColumn: {
    width: "60%",  // Increase the width of the first column
    flexWrap: "wrap",
  },


  table: {
    width: "100%",
    marginTop:10,
    borderStyle: "solid",
    borderColor: "#b2b2b2",
    borderWidth: 1,
  },
  tableRow: {
    flexDirection: "row",
    borderBottomColor: "#b2b2b2",
    borderBottomWidth: 1,
  },
  tableCell: {
    padding: 8,
    fontSize: 10,
    flexWrap: "wrap",
    width: "100%"
  },
  tableHeader: {
    backgroundColor: "#f2f2f2",
    fontWeight: "bold",
  },
  
  otherColumns: {
    width: "10%",  // You can adjust this width as needed
  },



});

const generateInvoiceNumber = () => {
  // Generate a random number or string
  const randomNumber = Math.floor(Math.random() * 1000000); // Generate a random number between 0 and 999999
  const prefix = "INV"; // Prefix for the invoice number
  return `${prefix}-${randomNumber}`;
};


const InvoicePage = () => {
  // Retrieve items from localStorage
  const [billingTableData, setBillingTableData] = useState([]);

  useEffect(() => {
    // Retrieve items from localStorage and update state
    const dataFromLocalStorage = JSON.parse(localStorage.getItem("InvoiceData")) || [];
    setBillingTableData(dataFromLocalStorage);
    console.log("Billing Table Data:", dataFromLocalStorage);

  }, []);


  const invoiceNumber = generateInvoiceNumber();


  const invoiceData = {
    invoiceNumber: invoiceNumber,
    gstPercent: localStorage.getItem("GstPercent"),
    customer: {
      name: localStorage.getItem("Name"),

      
    },
    items: billingTableData,
    totalAmount: billingTableData.reduce((total, item) => total + item.total, 0),
  };

  return (
    <PDFViewer style={{ width: "100%", height: "100vh" }}>
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.heading}>Invoice</Text>
           
            <View style={styles.customerInfo}>

              <Text>Invoice Number: {invoiceData.invoiceNumber}</Text>
              <Text>{localStorage.getItem("Name")}</Text>
              <Text>{ localStorage.getItem("company")}</Text>

              <Text>{localStorage.getItem("phone_number")}</Text>
              <Text>{ localStorage.getItem("city")}</Text>

            </View>
            <View style={styles.table}>
              <View style={[styles.tableRow, styles.tableHeader]}>
                <Text style={[styles.tableCell, styles.firstColumn]}>Description</Text>
                <Text style={[styles.tableCell, styles.otherColumns]}>Quantity</Text>
                <Text style={[styles.tableCell, styles.otherColumns]}>Price</Text>
                <Text style={[styles.tableCell, styles.otherColumns]}>Amount</Text>

              </View>
              {invoiceData.items.map((item, index) => (
                <View key={index} style={styles.tableRow}>
                  <Text style={[styles.tableCell, styles.firstColumn]}>                      {item.roller_size && item.roller_size !== "null" && (
                        <>
                          {item.roller_size}
                          {((item.pipe_size && item.pipe_size !== "null") ||
                            (item.cat && item.cat !== "null") ||
                            (item.roller_name && item.roller_name !== "null") ||
                            (item.cap && item.cap !== "null") ||
                            (item.poly && item.poly !== "null") ||
                            (item.nutHandle && item.nutHandle !== "null")) &&
                            " --> "}
                        </>
                      )}
                      {item.pipe_size && item.pipe_size !== "null" && (
                        <>
                          {item.pipe_size}
                          {((item.cat && item.cat !== "null") ||
                            (item.roller_name && item.roller_name !== "null") ||
                            (item.cap && item.cap !== "null") ||
                            (item.poly && item.poly !== "null") ||
                            (item.nutHandle && item.nutHandle !== "null")) &&
                            " --> "}
                        </>
                      )}
                      {item.cat && item.cat !== "null" && (
                        <>
                          {item.cat}
                          {((item.roller_name && item.roller_name !== "null") ||
                            (item.cap && item.cap !== "null") ||
                            (item.poly && item.poly !== "null") ||
                            (item.nutHandle && item.nutHandle !== "null")) &&
                            " --> "}
                        </>
                      )}
                      {item.roller_name && item.roller_name !== "null" && (
                        <>
                          {item.roller_name}
                          {((item.cap && item.cap !== "null") ||
                            (item.poly && item.poly !== "null") ||
                            (item.nutHandle && item.nutHandle !== "null")) &&
                            " --> "}
                        </>
                      )}
                      {item.cap && item.cap !== "null" && (
                        <>
                          {item.cap}
                          {item.poly && item.poly !== "null" && " --> "}
                        </>
                      )}
                      {item.poly && item.poly !== "null" && (
                        <>
                          {item.poly}
                          {item.nutHandle &&
                            item.nutHandle !== "null" &&
                            " --> "}
                        </>
                      )}
                      {item.nutHandle && item.nutHandle !== "null" && (
                        <>{item.nutHandle}</>
                      )}
                      {item.spray_name && item.spray_name !== "null" && (
                        <>{item.spray_name}</>
                      )}
                      {item.tools && item.tools !== "null" && <>{item.tools}</>}</Text>
                  <Text style={[styles.tableCell, styles.otherColumns]}>{item.quantity}</Text>
                  <Text style={[styles.tableCell, styles.otherColumns]}>{item.price}</Text>
                  <Text style={[styles.tableCell, styles.otherColumns]}>{item.price*item.quantity}</Text>
                </View>
              ))}
              
            </View>
            <View style={styles.billingInfo}>
              <Text>Total Amount: { localStorage.getItem("totalAmountToPay")}</Text>
            </View>
            <View style={styles.billingData}>
              <Text>Amount(in cash): { (localStorage.getItem("amountInCash"))}</Text>
            </View>
            <View style={styles.billingData}>
              <Text>GST Amount: {localStorage.getItem("onlineAmount") }</Text>
              <Text>Applied GST : { (localStorage.getItem("gstAmount") - (localStorage.getItem("totalAmountToPay")- localStorage.getItem("amountInCash"))).toFixed(2)}</Text>
            </View>
            <View style={styles.billingData}>
              <Text>Amount(with gst) { localStorage.getItem("gstAmount")}</Text>
            </View>
            <view>
            <Text style={styles.heading}>Billing Calculations</Text>

            </view>
            <View style={styles.table}>
              <View style={[styles.tableRow, styles.tableHeader]}>
                <Text style={[styles.tableCell, styles.firstColumn]}>Description</Text>
                <Text style={[styles.tableCell, styles.otherColumns]}>T. Amount</Text>
                <Text style={[styles.tableCell, styles.otherColumns]}>Amount for GST</Text>
                <Text style={[styles.tableCell, styles.otherColumns]}>GST Applied</Text>
                <Text style={[styles.tableCell, styles.otherColumns]}> Amount(gst)</Text>

              </View>
              {invoiceData.items.map((item, index) => (
                <View key={index} style={styles.tableRow}>
                  <Text style={[styles.tableCell, styles.firstColumn]}>                      {item.roller_size && item.roller_size !== "null" && (
                        <>
                          {item.roller_size}
                          {((item.pipe_size && item.pipe_size !== "null") ||
                            (item.cat && item.cat !== "null") ||
                            (item.roller_name && item.roller_name !== "null") ||
                            (item.cap && item.cap !== "null") ||
                            (item.poly && item.poly !== "null") ||
                            (item.nutHandle && item.nutHandle !== "null")) &&
                            " --> "}
                        </>
                      )}
                      {item.pipe_size && item.pipe_size !== "null" && (
                        <>
                          {item.pipe_size}
                          {((item.cat && item.cat !== "null") ||
                            (item.roller_name && item.roller_name !== "null") ||
                            (item.cap && item.cap !== "null") ||
                            (item.poly && item.poly !== "null") ||
                            (item.nutHandle && item.nutHandle !== "null")) &&
                            " --> "}
                        </>
                      )}
                      {item.cat && item.cat !== "null" && (
                        <>
                          {item.cat}
                          {((item.roller_name && item.roller_name !== "null") ||
                            (item.cap && item.cap !== "null") ||
                            (item.poly && item.poly !== "null") ||
                            (item.nutHandle && item.nutHandle !== "null")) &&
                            " --> "}
                        </>
                      )}
                      {item.roller_name && item.roller_name !== "null" && (
                        <>
                          {item.roller_name}
                          {((item.cap && item.cap !== "null") ||
                            (item.poly && item.poly !== "null") ||
                            (item.nutHandle && item.nutHandle !== "null")) &&
                            " --> "}
                        </>
                      )}
                      {item.cap && item.cap !== "null" && (
                        <>
                          {item.cap}
                          {item.poly && item.poly !== "null" && " --> "}
                        </>
                      )}
                      {item.poly && item.poly !== "null" && (
                        <>
                          {item.poly}
                          {item.nutHandle &&
                            item.nutHandle !== "null" &&
                            " --> "}
                        </>
                      )}
                      {item.nutHandle && item.nutHandle !== "null" && (
                        <>{item.nutHandle}</>
                      )}
                      {item.spray_name && item.spray_name !== "null" && (
                        <>{item.spray_name}</>
                      )}
                      {item.tools && item.tools !== "null" && <>{item.tools}</>}</Text>
                  <Text style={[styles.tableCell, styles.otherColumns]}>{item.price*item.quantity}</Text>
                  <Text style={[styles.tableCell, styles.otherColumns]}>
  {(localStorage.getItem("GstPercent") ? ((item.price*item.quantity) * ((localStorage.getItem("GstPercent"))/100)): (item.price*item.quantity)).toFixed(2)}
</Text>

                  <Text style={[styles.tableCell, styles.otherColumns]}>{(0.18 * (item.price*item.quantity) * ((localStorage.getItem("GstPercent"))/100)).toFixed(2)}</Text>
                  <Text style={[styles.tableCell, styles.otherColumns]}>{((0.18*(item.price*item.quantity) * ((localStorage.getItem("GstPercent"))/100))+ ((item.price*item.quantity) * ((localStorage.getItem("GstPercent"))/100))).toFixed(2)}</Text>
                </View>
              ))}
              
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default InvoicePage;
